<script>
  export let error;
  import * as Sentry from "@sentry/browser";

  Sentry.captureException(error);
</script>

<main>
  <h2>Sorry</h2>

  <p>Something went wrong!</p>
</main>

<div class="btn-container">
  <a class="btn btn--primary" href="https://druidry.org"
    >Back to the OBOD website</a
  >
</div>
